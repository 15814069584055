import clsx, { type ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

import { extendedTheme } from './extendedTheme.ts';

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': Object.keys(extendedTheme.fontSize).map(
        (key) => `text-${key}`,
      ),
    },
  },
});

export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));
