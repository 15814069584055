
export default function Error({ error }) {
  console.log(error)
  if (error == 404) {
    return (
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-row p-8 max-w-screen-lg">
          <div className="flex-grow-0 flex-shrink-0 w-1/2 p-8 text-gray-700">
            <h1 className="text-2xl font-medium">Looks like we took a wrong turn.</h1>
            <p>We can't find the page you're looking for. Head back to the main page and try again.</p>
          </div>
          <img className="flex-grow-0 flex-shrink-0 w-1/3" src="/images/error2.webp"></img>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-row p-8 max-w-screen-lg">
          <div className="flex-grow-0 flex-shrink-0 w-1/2 p-8 text-gray-700">
            <h1 className="text-2xl font-medium">Whoops, something went wrong.</h1>
            <p>We're working on fixing this. Head back to the main page and try again.</p>
          </div>
          <img className="flex-grow-0 flex-shrink-0 w-1/3" src="/images/error2.webp"></img>
        </div>
      </div>
    );
  }
};
