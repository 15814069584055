import { type ReactNode } from 'react';

import { cn } from '@/utils/cn';

export const ContainerLayout = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('mx-auto max-w-7xl sm:px-6 lg:px-8', className)}>
      {children}
    </div>
  );
};
