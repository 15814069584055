
import { api, FetchError } from '@/lib/api';
import { type GetUserResponse } from '@/types/api';

export async function setTosAccepted({ userSub }: { userSub: string }) {
  try {
    const { data } = await api.get<GetUserResponse>(`/api/users/${userSub}/accept_tos`);

    return { isAdmin: data.is_admin, accountId: data.account_id, tosAccepted: data.tos_accepted};
  } catch (error) {
    throw new FetchError(
      'An error occurred while fetching the user',
      error.response.status,
      error.response.data,
    );
  }
}