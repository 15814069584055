import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import SpinningLoader from '@/components/SpinningLoader';
import { useUser } from '@/features/users/api/getUser';
import { Card } from '@/components/Card';
import { Button } from '@tremor/react';
import { Checkbox } from '@/components/Checkbox';
import { Label } from '@/components/Label';
import { setTosAccepted } from '@/features/users/api/setTOS';
import { useQueryClient } from '@tanstack/react-query';

export const Welcome = () => {
    const { user } = useAuth0();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [checked, setChecked] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const acceptTOS = async () => {
        setIsLoading(true);
        try {
            await setTosAccepted({ userSub: user.sub });
            queryClient.invalidateQueries();
            navigate('/');
        } catch (error) {
            console.error("Error accepting TOS:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const userQuery = useUser({
        userSub: user.sub,
        queryConfig: {
            enabled: !!user?.sub,
        },
    });

    useEffect(() => {
        if (userQuery.data?.tosAccepted) {
            navigate('/');
        }
    }, [userQuery, navigate]);

    if (!user || userQuery.isPending || isLoading || !userQuery.data) {
        return <SpinningLoader />;
    }

    return (
        <div style={{ backgroundImage: 'url(./public/images/background_gradient.png)' }} className='bg-no-repeat bg-fixed bg-cover top-0 bottom-0 left-0 right-0 absolute flex items-center justify-center'>
            <Card className='mx-auto inline-block w-96'>
                <h1 className="mx-4 text-2xl font-medium text-gray-700 mb-5">Welcome</h1>
                <hr />
                <div className="flex items-center gap-2 mx-2 my-2">
                    <Checkbox className='mr-1' id="tosChbx" checked={checked}
                        onCheckedChange={() => setChecked(!checked)}
                    />
                    <Label htmlFor="tosChbx" className='text-xs text-gray-400'>Accept our <a href='/tos.txt' className='text-blue-400'>Terms of Service</a></Label>
                </div>
                <Button className='w-full text-center' onClick={acceptTOS} disabled={!checked}>Continue</Button>
            </Card>
        </div>
    );
};
