import { type Config } from 'tailwindcss';
import colors from 'tailwindcss/colors';

export const extendedTheme = {
  colors: {
    // light mode
    tremor: {
      brand: {
        faint: colors.yellow[50],
        muted: colors.yellow[200],
        subtle: colors.yellow[400],
        DEFAULT: colors.yellow[500],
        emphasis: colors.yellow[700],
        inverted: colors.white,
      },
      background: {
        muted: colors.gray[50],
        subtle: colors.gray[100],
        DEFAULT: colors.white,
        emphasis: colors.gray[700],
      },
      border: {
        DEFAULT: colors.gray[200],
      },
      ring: {
        DEFAULT: colors.gray[200],
      },
      content: {
        subtle: colors.gray[400],
        DEFAULT: colors.gray[500],
        emphasis: colors.gray[700],
        strong: colors.gray[900],
        inverted: colors.white,
      },
    },
    backgroundImage: {
      'custom-gradient': "url('./public/images/background_gradient.png')",
    },
    // dark mode
    //   'dark-tremor': {
    //     brand: {
    //       faint: '#0B1229',
    //       muted: colors.yellow[950],
    //       subtle: colors.yellow[800],
    //       DEFAULT: colors.yellow[500],
    //       emphasis: colors.yellow[400],
    //       inverted: colors.yellow[950],
    //     },
    //     background: {
    //       muted: '#131A2B',
    //       subtle: colors.gray[800],
    //       DEFAULT: colors.gray[900],
    //       emphasis: colors.gray[300],
    //     },
    //     border: {
    //       DEFAULT: colors.gray[800],
    //     },
    //     ring: {
    //       DEFAULT: colors.gray[800],
    //     },
    //     content: {
    //       subtle: colors.gray[600],
    //       DEFAULT: colors.gray[500],
    //       emphasis: colors.gray[200],
    //       strong: colors.gray[50],
    //       inverted: colors.gray[950],
    //     },
    //   },
  },
  boxShadow: {
    // light
    'tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    'tremor-card':
      '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    'tremor-dropdown':
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    // // dark
    // 'dark-tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    // 'dark-tremor-card':
    //   '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    // 'dark-tremor-dropdown':
    //   '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  },
  borderRadius: {
    'tremor-small': '0.375rem',
    'tremor-default': '0.5rem',
    'tremor-full': '9999px',
  },
  fontSize: {
    'tremor-label': ['0.75rem', { lineHeight: '1rem' }],
    'tremor-default': ['0.875rem', { lineHeight: '1.25rem' }],
    'tremor-title': ['1.125rem', { lineHeight: '1.75rem' }],
    'tremor-metric': ['1.875rem', { lineHeight: '2.25rem' }],
    // 13px size / 16px high
    'xs+': ['0.8125rem', { lineHeight: '1rem' }],
    // 15px size / 20px high
    'sm+': ['0.9375rem', { lineHeight: '1.25rem' }],
  },
  spacing: {
    '4.5': '1.125rem',
  },
} satisfies Config['theme'];
