import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import SpinningLoader from '@/components/SpinningLoader';

export const ProtectedRoute = ({ component, ...args }) => {
  const { isLoading } = useAuth0();

  const Component = withAuthenticationRequired(component, args);

  if (isLoading) {
    return <SpinningLoader />;
  }

  return <Component />;
};
